import { compileDestinations, page, params, query, surface } from '@sevenrooms/core/navigation'

export const routes = compileDestinations(
  page(
    `/`,
    []
  )({
    admin: page(
      'admin/',
      []
    )({
      ongoingEmailCampaignTemplates: page(
        'ongoing-email-campaign-templates/',
        []
      )({
        create: page(
          'create/',
          []
        )({
          reservationModal: surface('reservation-modal/', []),
          unsubscribeModal: surface('unsubscribe-modal/', []),
          menuModal: surface('menu-modal/', []),
          crossPromotionModal: surface('cross-promotion-modal/', []),
        }),
        duplicate: page(params => `duplicate/${params.templateId}/`, [], { templateId: params.string }),
        edit: page(params => `template/${params.templateId}/`, [], { templateId: params.string }),
        sendTestEmailModal: surface('send-test-email-modal/', []),
        toggleEmailCampaignTemplateStatusModal: surface('toggle-campaign-template-status-modal/', []),
        confirmDeleteModal: surface('confirm-delete-modal/', []),
      }),
      oneTimeEmailCampaignsTemplates: page(() => 'one-time-email-campaign-templates/', [], { tab: query.optional.string })({
        create: page(
          'create/',
          []
        )({
          publishModal: surface('publish/', []),
        }),
        duplicate: page(params => `/${params.templateId}/duplicate`, [], {
          templateId: params.string,
        })({
          publishModal: surface('publish/', []),
        }),
        edit: page(params => `/${params.templateId}/update`, [], { templateId: params.string })({
          archiveModal: surface('archive/', []),
          deactivateModal: surface('deactivate/', []),
          saveDeactivateModal: surface('save-deactivate/', []),
          unarchiveModal: surface('unarchive/', []),
          duplicateModal: surface('duplicate/', []),
          unsavedModal: surface('unsaved/', []),
        }),
        sendTestEmailModal: surface('send-test-email-modal/', []),
        reservationModal: surface('reservation-modal/', []),
        unsubscribeModal: surface('unsubscribe-modal/', []),
        menuModal: surface('menu-modal/', []),
        crossPromotionModal: surface('cross-promotion-modal/', []),
      }),
      paymentsDebugger: page(params => `venue/${params.venueId}/payments-debugger`, [], {
        venueId: params.string,
        searchBy: query.optional.string,
        searchString: query.optional.string,
      }),
      syncBlobs: page(params => `venue/${params.venueId}/syncblob`, [], {
        venueId: params.string,
        date: query.optional.date,
        useFancyDisplay: query.optional.bool,
        showDeletedModels: query.optional.bool,
        modelNames: query.optional.string,
        search: query.optional.string,
      }),
      venueEdit: page(params => `venue/${params.venueId}/edit`, [], {
        venueId: params.string,
      }),
      packageProvisioning: page(params => `venue/${params.venueId}/package-provisioning`, [], {
        venueId: params.string,
      })({
        review: page('/review', []),
      }),
      venueGroupEdit: page(params => `venuegroup/${params.venueGroupId}/edit`, [], {
        venueGroupId: params.string,
      }),
      venueGroupMerge: page('venue_group_merge/', []),
      clientappsEdit: page(params => `clientapps/${params.clientAppId}/edit`, [], {
        clientAppId: params.string,
      }),
      clientappsManager: page(params => `${params.entityType}/${params.entityId}/clientapps`, [], {
        entityId: params.string,
        entityType: params.string,
      }),
    }),
    api: page(
      'api-yoa/',
      []
    )({
      packageWizardVenueDownload: page(params => `admin/venue/${params.venueId}/package-wizard/download`, [], {
        venueId: params.string,
      }),
      packageWizardReportDownload: page(params => `admin/package-wizard/${params.reportId}/download`, [], {
        reportId: params.string,
      }),
    }),
    customDomains: page('custom_domains/', []),
    manager: page(params => `manager/${params.venueKey}/`, [], { venueKey: params.string })({
      account: page(params => `account/${params.userId}`, [], { userId: params.string }),
      settings: page(
        'settings/',
        []
      )({
        venue: page('venue', []),
        widgets: page(
          'widgets/',
          []
        )({
          eventWidgetSettings: page('events', []),
          reservationWidgetSettings: page('dining', []),
          waitlistWidgetSettings: page('waitlist', []),
        }),
      }),
      events: page('events', []),
      profileInformation: page('venueprofile', []),
      upgrades: page('upsells', []),
      trackingLinks: page('links', []),
      userAccounts: page('access/user/list', []),
      manage: page(
        'manage/',
        []
      )({
        clientTags: page('tags', []),
        reservationTags: page('reservationtags', []),
        membershipGroups: page('membershipgroups', []),
        taxRates: page('tax_rates', []),
        paymentGateway: page('bank', []),
        daysOfOperation: page('daysofoperation', []),
        shifts: page('capacity/schedule', []),
        accessRules: page('capacity/accessrules', []),
        dailyProgram: page('program', []),
        blackoutDates: page('blackoutdates', []),
        perks: page('perks/list', []),
        rooms: page('capacity/rooms/edit', []),
        seatingAreas: page('capacity/areas/edit', []),
        tables: page('capacity/table/edit', []),
        tableCombinations: page('table_combos', []),
        reservationStatuses: page('service_status', []),
        bookedByNames: page('bookedbynames/edit', []),
        serverNames: page('servernames/edit', []),
      }),
    }),
    manager2: page(params => `manager2/${params.venueKey}/`, [], { venueKey: params.string })({
      availability: page(() => 'availability/', [], {
        date: query.optional.string,
        audience: query.optional.string,
        accessPersistentIds: query.optional.array(query.optional.string),
        seatingAreaIds: query.optional.array(query.optional.string),
        durationOverride: query.optional.number,
        shiftPersistentId: query.optional.string,
        clientTagIds: query.optional.array(query.optional.string),
      }),
      marketing: page(
        'marketing/',
        []
      )({
        home: page('home/', []),
        autotags: page('autotags/', []),
        upgrades: page(
          'upgrades/',
          []
        )({
          textMarketing: page('text-marketing', []),
          emailMarketing: page('email-marketing', []),
        }),
        profileInformation: page('venueprofile/', []),
        oneTimeSMSCenter: page(
          'sms-campaigns/',
          []
        )({
          smsCampaignTemplates: page('sms-campaign-templates/', []),
          createOneTimeSMS: page(() => `campaign/`, [], {
            campaignToDuplicateId: query.optional.string,
            parentTemplateId: query.optional.string,
          }),
          confirmSendModal: surface('confirm-send/', []),
          sendTestSMSModal: surface('send-test-sms/', []),
          cancelSMSEditModal: surface('cancel-sms-edit-modal/', []),
          editOneTimeSMS: page(params => `campaign/${params.campaignId}/`, [], {
            campaignId: params.string,
          }),
          viewOneTimeSMS: page(params => `campaign/view/${params.campaignId}/`, [], {
            campaignId: params.string,
          }),
          smsBuilderFromScratch: page(() => 'from-scratch/', [], {
            campaignToDuplicateId: query.optional.string,
          })({
            offerModal: surface('offer-modal/', []),
            noOfferModal: surface('no-offer-modal/', []),
          }),
          confirmEditModal: surface(params => `edit-modal/${params.campaignId}/`, [], { campaignId: params.string }),
          confirmArchiveModal: surface(params => `archive-modal/${params.campaignId}/`, [], { campaignId: params.string }),
          archiveSMS: surface(params => `archive-sms/${params.campaignId}/`, [], { campaignId: params.string }),
          deactivateSMSCampaignModal: surface('deactivate-sms-campaign-modal/', []),
        }),
        emailCenter: page(
          'email-center/',
          []
        )({
          emails: page(
            'emails/',
            []
          )({
            emailTemplate: page(
              'email-templates/',
              []
            )({
              useTemplate: surface('use-template/', []),
            }),
            createNewEmail: surface('create-new-email/', []),
            emailCampaignTemplates: page('email-campaign-templates/', []),
            deactivateEmailCampaignModal: surface('deactivate-email-campaign-modal/', []),
            emailBuilder: page(
              'email-builder/',
              []
            )({
              emailBuilderFromTemplate: page(() => 'from-template/', [], {
                parentTemplateId: query.optional.string,
              }),
              emailBuilderFromScratch: page(() => 'from-scratch/', [], {
                campaignToDuplicateId: query.optional.string,
              })({
                reservationModal: surface('reservation-modal/', []),
                unsubscribeModal: surface('unsubscribe-modal/', []),
                offerModal: surface('offer-modal/', []),
                noOfferModal: surface('no-offer-modal/', []),
                menuModal: surface('menu-modal/', []),
                crossPromotionModal: surface('cross-promotion-modal/', []),
                assistantModal: surface('assistant-modal/', []),
                assistantFormModal: surface('assistant-form-modal/', []),
              }),
              sendTestEmailModal: surface('send-test-email-modal/', []),
              cancelEmailEditModal: surface('cancel-email-edit-modal/', []),
              reservationModal: surface('reservation-modal/', []),
              unsubscribeModal: surface('unsubscribe-modal/', []),
              offerModal: surface('offer-modal/', []),
              noOfferModal: surface('no-offer-modal/', []),
              menuModal: surface('menu-modal/', []),
              crossPromotionModal: surface('cross-promotion-modal/', []),
              assistantModal: surface('assistant-modal/', []),
              assistantFormModal: surface('assistant-form-modal/', []),
            }),
            editEmail: page(params => `template-campaign/${params.campaignId}/`, [], { campaignId: params.string })({
              updatesModal: surface('updates/', []),
            }),
            editEmailFromScratch: page(params => `campaign/${params.campaignId}/`, [], {
              campaignId: params.string,
              validate: query.optional.bool,
            }),
            archiveEmail: surface(params => `archive-email/${params.campaignId}/`, [], { campaignId: params.string }),
          }),
        }),
        offers: page(
          'experiences2/',
          []
        )({
          createExperience: page('create/', []),
          createExperienceFromTemplate: page(params => `create-from-template/${params.templateId}`, [], { templateId: params.string }),
          editExperience: page(params => `${params.experienceId}/edit`, [], {
            experienceId: params.string,
          }),
        }),
        groupBookings: page(
          'group-bookings/',
          []
        )({
          createSpace: page('space/', []),
          editSpace: page(params => `space/${params.spaceId}/`, [], {
            spaceId: params.string,
          }),
        }),
        reviews: page('reviews/', []),
        promoCodesManagement: page(
          'promo-codes/',
          []
        )({
          deleteModal: surface('delete-modal/', []),
        }),
        promoCode: page(params => `promo-code/${params.promoCodeKey}`, [], { promoCodeKey: params.string }),
        oneTimeEmailCenter: page(
          'email-campaigns/',
          []
        )({
          createOneTimeEmail: page(() => `campaign/`, [], {
            campaignToDuplicateId: query.optional.string,
            parentTemplateId: query.optional.string,
          }),
          confirmSendModal: surface('confirm-send/', []),
          editOneTimeEmail: page(params => `campaign/${params.campaignId}/`, [], {
            campaignId: params.string,
          }),
          viewDeepDive: page(params => `campaign/dashboard/${params.campaignId}`, [], {
            campaignId: params.string,
            tab: query.optional.string,
          }),
          viewOneTimeEmail: page(params => `campaign/view/${params.campaignId}/`, [], {
            campaignId: params.string,
          }),
          emailCampaignTemplates: page('email-campaign-templates/', []),
          confirmEditModal: surface(params => `edit-modal/${params.campaignId}/`, [], { campaignId: params.string }),
          confirmArchiveModal: surface(params => `archive-modal/${params.campaignId}/`, [], { campaignId: params.string }),
        }),
        referrals: page(
          'referral-program/',
          []
        )({
          referralProgramEnableModal: surface(params => `referral-activate/${params.referralProgramEnabled}/`, [], {
            referralProgramEnabled: params.bool,
          }),
          referralProgramSaveModal: surface('referral-program-save/', []),
        }),
        perks2: page(
          'perks2.0/',
          []
        )({
          createEarlyAccess: page('create-early-access/', []),
          createExclusive: page('create-exclusive/', []),
          createAccessRule: surface('create-access-rule/', []),
          editExclusive: page(params => `${params.perkId}/edit-exclusive/`, [], {
            perkId: params.string,
          }),
          editEarlyAccess: page(params => `${params.perkId}/edit-early-access/`, [], {
            perkId: params.string,
          }),
          selectAccessRule: surface('select-access-rule/', []),
          editAudienceTier: surface('edit-audience-tier/', []),
          create: page('create/', []),
          edit: page(params => `${params.perkId}/edit/`, [], {
            perkId: params.string,
          }),
          deleteModal: surface('delete/', []),
        }),
      }),
      orders: page(() => 'orders', [], { date: query.optional.string })({
        refundModal: surface('refund-modal/', []),
      }),

      reporting: page(
        'reporting/',
        []
      )({
        home: page(() => `home/`, [], {}),
        embed: page(params => `embed/${params.type}/${params.id}`, [], {
          type: params.string,
          id: params.string,
        }),
        outgoingemails: page(() => `outgoingemails/`, [], {}),
        guestcenterImport: page(() => `guestcenter-import/`, [], {}),
        revenue: page(() => `revenue/`, [], { filter: query.optional.string }),
      }),

      insights: page(() => 'insights/', [], { iid: query.optional.string })({
        embed: page(params => `embed/${params.type}/${params.id}`, [], {
          type: params.string,
          id: params.string,
        }),
      }),

      settings: page(
        'settings/',
        []
      )({
        venue: page(() => 'venue', [], {
          activeTab: query.optional.string,
        }),
        availability: page(
          'availability/',
          []
        )({
          accessRulesSettings: page('accessrules', []),
        }),
        jobs: page(params => `jobs/${params.tableType}`, [], {
          tableType: params.optional.string,
          currentPage: query.optional.number,
          rowsPerPage: query.optional.number,
        }),
        fees: page('fees', []),
        ordering: page(
          'ordering/',
          []
        )({
          sites: page('sites', []),
          site: page(params => `site/${params.id}`, [], {
            id: params.optional.string,
          }),
          menus: page('menus', []),
          menu: page(params => `menu/${params.id}`, [], {
            id: params.optional.string,
          }),
          inventoryProducts: page('inventory/products', []),
          inventoryProduct: page(params => `inventory/product/${params.type}/${params.id}`, [], {
            type: params.optional.string,
            id: params.optional.string,
          }),
          eightySixProduct: page('86product', []),
          checkout: page('checkout', []),
          pacingRules: page('pacing-rules', []),
          pacingRule: page(params => `pacing-rules/${params.id}`, [], {
            id: params.string,
          }),
          serviceFees: page('service-fees', []),
          brand: page('brand', []),
          notificationSettings: page('notification_settings', []),
          deliveryIntegrations: page('delivery_integrations', []),
          roomNumbers: page('room_numbers', []),
        }),
        bookingChannels: page('bookingchannels/', []),
        accessRuleBulkEdit: page('access-rule-bulk-edit', []),
        iVvyTableMapping: page('ivvy_table_mapping', []),
        emailServiceProviders: page('emailserviceproviders/', []),
        reviewChannels: page('reviewchannels/', []),
        pointofsale: page('pointofsale/', []),
        paymentGateway2: page('payment-gateways/', []),
        paymentIntegration: page(
          'payment-integration/',
          []
        )({
          view: page('view', []),
          edit: page(params => (params.provider ? `edit/${params.provider}` : `edit`), [], {
            provider: params.optional.string,
          }),
        }),
        customAudiences: page(
          'custom-audiences/',
          []
        )({
          view: page('view', []),
          add: page('add', []),
          edit: page(params => (params.id ? `edit/${params.id}` : `edit`), [], {
            id: params.optional.string,
          }),
        }),
        messagingIntegration: page('messaging-integrations/', []),
        pointOfSaleIntegration: page(
          'posi/',
          []
        )({
          view: page('view', []),
          edit: page('edit', []),
          auth: page(
            'auth/',
            []
          )({
            simphony: page('simphony', []),
          }),
        }),
        widgetSettings: page(
          'widgets/',
          []
        )({
          reservationWidgetSettingsV2: page(_params => 'reservation', [], {
            activeTab: query.optional.string,
          }),
        }),
        widgets: page(
          'widget_settings/',
          []
        )({
          reservation: page('reservation_widget_language', []),
          waitlist: page('waitlist_language', []),
          orderingSites: page('ordering_sites_language', []),
        }),
        subscriptionPage: page('subscription', []),
        userAccountsSettings: page(
          'user_accounts/',
          []
        )({
          list: page('', []),
          detailed: page(params => `${params.id}`, [], {
            id: params.string,
          }),
        }),
        landingPage: page('landingpage', []),
        pages: page(
          'page_settings/',
          []
        )({
          feedback: page('feedback_page_language', []),
          receipt: page('receipt_page_language', []),
          paylink: page('paylink_page_language', []),
          cancellation: page('cancellation_page_language', []),
          confirmation: page('confirmation_page_language', []),
        }),
        language: page('language_settings/', []),
        emails: page(
          'email_settings/',
          []
        )({
          reservationConfirmation: page('reservation_confirmation', []),
          reservationUpdate: page('reservation_update', []),
          reservationCancellation: page('reservation_cancellation', []),
          feedbackRequestLanguage: page('feedback_request_language', []),
          orderFeedbackRequestLanguage: page('order_feedback_request_language', []),
          paylinkRequestLanguage: page('paylink_request_language', []),
          reservationReminder: page('reservation_reminder', []),
          completedTransactionLanguage: page('completed_transaction_language', []),
          guestlistConfirmation: page('guestlist_confirmation', []),
        }),
        SMS: page(
          'sms_settings/',
          []
        )({
          reservationLanguage: page('sms_res_language', []),
          waitlistLanguage: page('sms_waitlist_language', []),
          orderMenuLanguage: page('sms_order_menu_language', []),
          ordering: page('sms_ordering', []),
          customSms: page('custom_sms', []),
        }),
        policies: page(
          'policy_settings/',
          []
        )({
          booking: page('booking_policies', []),
          payment: page('payment_policies', []),
        }),
        reservationImport: page('reservation-import/', []),
        clientImport: page('client-import/', []),
      }),
    }),
    logout: page('logout', []),
    help: page('help', []),
    reservations: page(params => `reservations/${params.venueKey}/${params.trackingSlug}`, [], {
      venueKey: params.string,
      trackingSlug: params.optional.string,
      venues: params.optional.string,
      modify_res: query.optional.string,
      modify_upgrades: query.optional.string,
      default_date: query.optional.string,
      default_time: query.optional.string,
      default_party_size: query.optional.string,
      client_id: query.optional.string,
      duration_picker: query.optional.string,
      _ct: query.optional.string,
      lang: query.optional.string,
      token: query.optional.string,
      request_id: query.optional.string,
      experience_id: query.optional.string,
      max_days_out: query.optional.string,
      waitlist_id: query.optional.string,
      referral_id: query.optional.string,
    }),
    direct: page(
      'direct/',
      []
    )({
      modifyReservation: page(params => `modify-reservation/${params.token}`, [], {
        token: params.optional.string,
        lang: query.string,
        modify_upgrades: query.optional.string,
      }),
    }),
    explore: page(params => `explore/${params.venueKey}/`, [], {
      venueKey: params.string,
      lang: query.optional.string,
      tracking: query.optional.string,
      utm_source: query.optional.string,
      utm_medium: query.optional.string,
      utm_campaign: query.optional.string,
      utm_content: query.optional.string,
      utm_id: query.optional.string,
      utm_term: query.optional.string,
      fbclid: query.optional.string,
    })({
      reservations: page(() => 'reservations/', [], {
        date: query.optional.string,
        start_time: query.optional.string,
        halo: query.optional.string,
        end_time: query.optional.string,
        party_size: query.optional.string,
        venues: query.optional.string,
        timeslot_id: query.optional.string,
        timeslot_time: query.optional.string,
        primary_venue_id: query.optional.string, // Should only be used in Create flow
        client_id: query.optional.string,
        request_id: query.optional.string,
        _ga: query.optional.string,
        _gl: query.optional.string,
        instant_experiences_enabled: query.optional.string,
        error_display: query.optional.string,
        error_message: query.optional.string,
        client_token: query.optional.string,
        searchTab: query.optional.string,
      })({
        create: page(
          () => 'create/',
          [],
          {}
        )({
          search: page(() => 'search/', [], {}),
          upgrades: page(() => 'upgrades/', [], {}),
          checkout: page(() => 'checkout/', [], {}),
        }),
        modify: page(() => 'modify/', [], { token: query.string })({
          search: page(() => 'search/', [], {}),
          upgrades: page(() => 'upgrades/', [], {}),
          checkout: page(() => 'checkout/', [], {}),
        }),
        prearrival: page(
          () => 'prearrival/',
          [],
          {}
        )({
          upgrades: page(() => 'upgrades/', [], {}),
          checkout: page(() => 'checkout/', [], {}),
        }),
        search: page(() => 'search/', [], {}),
        errorModal: surface('error-modal/', []),
        manage: page(() => 'manage/', [], {
          token: query.string,
          is_confirm: query.optional.string,
          is_success: query.optional.string,
          is_success_edit: query.optional.string,
        })({
          passed: page('passed', []),
          canceled: page('canceled', []),
          modifyModal: surface('modify-modal/', []),
          cancelModal: surface('cancel-modal/', []),
          errorUpgrades: surface('error-upgrades-modal/', []),
          addUpgradesModal: surface('add-upgrades-modal/', []),
        }),
        alerts: page(
          () => 'alerts/',
          [],
          {}
        )({
          canceled: page('canceled', []),
        }),
      }),
      subscription: page(() => 'subscription', [], {
        embed: query.optional.number,
      }),
    }),
    captureCustomerPayment: page(
      'payments/',
      []
    )({
      contractInfoStep: page('contract-info/', []),
      paymentStep: page(() => 'payment/', [], {
        customerPaymentSessionId: query.string,
        sfdcVenueId: query.optional.string,
      }),
      paymentFinal: page(() => 'payment-final/', [], {
        adyenSessionModelId: query.string,
        sessionId: query.string,
        redirectResult: query.string,
        isOneTokenForAll: query.bool,
        customerPaymentSessionId: query.string,
        sfdcVenueId: query.optional.string,
      }),
      venuesSelectionStep: page(() => 'venues-selection/', [], {
        customerPaymentSessionId: query.string,
      }),
      emailsCollectionStep: page(() => 'emails-collection/', [], {
        customerPaymentSessionId: query.string,
      }),
      submitted: page('submitted/', []),
      captureCompleted: page('completed/', []),
      error: page('error/', []),
      noRecordsFound: page('no-records-found/', []),
    }),
    loyaltySignup: page(params => `signup/${params.venueKey}/`, [], {
      venueKey: params.string,
      lang: query.optional.string,
      embed: query.optional.number,
    }),
    app: page(
      'app/',
      []
    )({
      reservations: page(params => `reservations/${params.venueKey}/`, [], {
        venueKey: params.string,
        lang: query.optional.string,
        tracking: query.optional.string,
        date: query.optional.string,
        start_time: query.optional.string,
        halo: query.optional.string,
        end_time: query.optional.string,
        party_size: query.optional.string,
        venues: query.optional.string,
        timeslot_id: query.optional.string,
        timeslot_time: query.optional.string,
        primary_venue_id: query.optional.string, // Should only be used in Create flow
        client_id: query.optional.string,
        request_id: query.optional.string,
        _ga: query.optional.string,
        _gl: query.optional.string,
        instant_experiences_enabled: query.optional.string,
        error_display: query.optional.string,
        error_message: query.optional.string,
        client_token: query.optional.string,
      })({
        create: page(
          () => 'create/',
          [],
          {}
        )({
          search: page(() => 'search/', [], {}),
          upgrades: page(() => 'upgrades/', [], {}),
          checkout: page(() => 'checkout/', [], {}),
        }),
        modify: page(() => 'modify/', [], { token: query.string })({
          search: page(() => 'search/', [], {}),
          upgrades: page(() => 'upgrades/', [], {}),
          checkout: page(() => 'checkout/', [], {}),
        }),
        prearrival: page(
          () => 'prearrival/',
          [],
          {}
        )({
          upgrades: page(() => 'upgrades/', [], {}),
          checkout: page(() => 'checkout/', [], {}),
        }),
        search: page(() => 'search/', [], {}),
        errorModal: surface('error-modal/', []),
        manage: page(() => 'manage/', [], {
          token: query.string,
          is_confirm: query.optional.string,
          is_success: query.optional.string,
          is_success_edit: query.optional.string,
        })({
          passed: page('passed', []),
          canceled: page('canceled', []),
          modifyModal: surface('modify-modal/', []),
          cancelModal: surface('cancel-modal/', []),
          errorUpgrades: surface('error-upgrades-modal/', []),
          addUpgradesModal: surface('add-upgrades-modal/', []),
        }),
        alerts: page(
          () => 'alerts/',
          [],
          {}
        )({
          canceled: page('canceled', []),
        }),
      }),
    }),
  })
)
